<template>
  <section
    id="smart-factory-solutions"
  >
    <br>
    <br>
    <br>
    <base-section-heading
      icon="mdi-factory"
      title="자동화 솔루션"
    />
    <v-tabs
      v-model="tab"
      centered
      show-arrows
    >
      <v-tab
        v-for="(t, i) in tabs"
        :key="i"
        :href="`#${t.toLowerCase()}`"
        class="text-capitalize"
      >
        {{ t }}
      </v-tab>
    </v-tabs>
    <br>
    <br>
    <v-container>
      <v-fade-transition
        group
        hide-on-leave
        tag="div"
        class="row"
      >
        <v-col
          v-for="(project, i) in projects"
          :key="project.title"
          class="gallery-card"
          cols="12"
        >
          <base-left-photo-two
            :src="project.src"
            :subheading="project.title"
            :heading="project.subtitle"
            :html="project.html"
          />
        </v-col>
      </v-fade-transition>
    </v-container>
    <br>
    <br>
  </section>
</template>

<script>
  export default {
    name: 'SectionSmartFactorySolutions',
    data: () => ({
      tab: '전체',
      tabs: [
        '전체',
        '보관',
        '피킹',
        '분류',
        '로봇',
        '스캐너',
      ],
    }),
    computed: {
      projects () {
        return [
          { title: '스태커 크레인', subtitle: '보관', html: '스태커 크레인 (Stacker Crane)은 스마트 웨어하우스에서 자동화된 입출고 작업을 수행하는 고가형 입출고 시스템입니다. 크레인 구조와 AS/RS (Automated Storage and Retrieval System) 기술을 결합하여 높은 효율성과 정확성을 제공합니다.', src: require('@/assets/shuttle_rack.jpg') },
          { title: '셔틀', subtitle: '보관', html: '셔틀 (Shuttle)는 스마트 웨어하우스에서 소형 화물을 효율적으로 처리하는 자동화 입출고 시스템입니다. 콤팩트한 크기와 높은 밀도의 보관 용량을 제공하며, 특히 B2C (Business to Consumer) 전자상거래 분야에서 널리 사용됩니다.', src: require('@/assets/miniload.jpg') },
          { title: 'DPS', subtitle: '피킹', html: 'DPS (Digital Picking System)은 물류센터에서 피킹 작업을 효율적으로 수행하기 위한 자동화 시스템입니다. 전통적인 피킹 방식과 달리, 피킹 작업자가 종이 피킹 리스트를 사용하여 화물을 찾는 대신, 디지털 장치를 사용하여 화물 위치와 수량 정보를 확인하고 피킹 작업을 수행합니다.', src: require('@/assets/dps.jpg') },
          { title: 'DAS', subtitle: '분류', html: 'DAS (Digital Assorting System)는 물류센터에서 상품을 자동으로 분류하는 시스템입니다. 전통적인 분류 방식과 달리, DAS는 바코드, RFID 태그, 이미지 인식 등의 기술을 사용하여 상품을 정확하고 빠르게 분류합니다.', src: require('@/assets/das.jpg') },
          { title: '컨베이어', subtitle: '분류', html: '컨베이어는 물류센터에서 상품이나 화물을 자동으로 이동시키는 시스템입니다. 컨베이어는 다양한 종류가 있으며, 물류센터의 특성에 따라 적합한 컨베이어를 선택하여 사용합니다.', src: require('@/assets/conveyor.jpg') },
          { title: '소터', subtitle: '분류', html: '소터 (Sorter) 는 물류센터에서 상품이나 화물을 자동으로 분류하는 시스템입니다. 바코드, RFID 태그, 이미지 인식 등의 기술을 사용하여 상품 정보를 수집 후 분류하여 연결된 컨베이어 벨트를 통해 상품을 정해진 위치로 이동시킵니다. ', src: require('@/assets/sorter.jpg') },
          { title: '수직 캐리어', subtitle: '분류', html: '수직 캐리어는 물류센터의 공간 활용도를 높이고 처리량을 향상시킬 수 있는 시스템입니다. 물류센터의 특성에 맞는 수직 캐리어를 선택하여 사용하면 창고 공간을 효율적으로 활용하고 작업 효율성을 높일 수 있습니다.', src: require('@/assets/vertical_carrier.jpg') },
          { title: 'AGV', subtitle: '로봇', html: 'AGV (Automated Guided Vehicle)는 자동화된 무인 운송차로, 사람의 조작 없이 자율적으로 이동하여 물품을 운반하는 차량입니다.', src: require('@/assets/agv.jpg') },
          { title: '아크 웰딩 로봇', subtitle: '로봇', html: '아크 웰딩 로봇 (Arc Welding Robot)은 용접 전원과 통합할 수 있는 표준 인터페이스를 제공하며, 시작점 감지 및 터치 감지 기능도 갖추고 있어 용접의 정확한 위치를 인식합니다.', src: require('@/assets/main_desc-1.jpg') },
          { title: '3D 비전 스캐너', subtitle: '스캐너', html: '3D 비전 스캐너는 상품의 위치, 크기, 형태 등을 3D로 인식하는 데 사용되는 장비입니다. 정확한 이미지 인식을 바탕으로 효율적인 상품의 분류가 가능합니다.', src: require('@/assets/3dvisionscanner.jpg') },
        ].filter(project => {
          if (this.tab.toLowerCase() === '전체') return true

          return project.subtitle.toLowerCase() === this.tab.toLowerCase()
        })
      },
    },
  }
</script>
